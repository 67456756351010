
.azayHeading {
  text-align: center;
  font-size: 3.8rem;
  color: #ffffff;
  margin-bottom: 0; /* Removed gap */
}

.italicText {
  font-style: italic;
  color: #ad0606;
}

.azayRow {
  display: flex;
  flex-direction: column; /* Align items vertically */
  align-items: center;
  padding: 2rem; /* Reduced padding */
  gap: 0; /* Removed gap */
}

.azayImage {
  text-align: center;
  margin-bottom: 1rem; /* Adjusted margin to control spacing */
}

.azayImage img {
  width: 30rem;
  height: auto;
  border-radius: 5%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.6);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.azayImage img:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(255, 255, 255, 0.8);
}

.azayContent {
  text-align: center;
  padding: 0; /* Removed padding */
}

.azayContent h3 {
  color: rgb(252, 5, 5);
  font-size: 2.5rem;
  margin-bottom: 0.5rem; /* Adjusted margin */
}

.azayTag {
  font-size: 2.4rem;
  color: #ffffff;
  font-weight: 600;
  margin-top: 0; /* Removed margin */
}

.introHobby {
  font-size: 1.4rem;
  color: #333;
}

.introHobby .highlight {
  color: #ff5722;
}

.italicText {
  font-style: italic;
  color: #ad0606;
}
.azayContent p {
  font-size: 1.5rem;
  margin: 1rem 0; /* Adjusted margin */
  font-family: "Nunito";
  font-weight: 600;
  text-transform: none;
}

.azayBoxContainer {
  display: flex;
  justify-content: center;
  margin: 1rem 0; /* Adjusted margin */
}

.azayBox p {
  text-transform: none;
}

.azayBox p span {
  color: #011aff;
}

@media screen and (max-width: 600px) {
  .azayImage {
    margin-top: 2rem;
  }

  .azayImage img {
    margin: 0 auto;
    width: 80%;
  }

  .azayRow {
    padding: 0.5rem;
  }

  .azayContent {
    padding: 1rem;
  }

  .azayBoxContainer {
    gap: 0;
  }
}
