body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to left, rgb(5, 8, 40), rgb(1, 4, 39));
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* Footer Container */
.footer {
  padding: 1.5rem 2%;
  color: #fff;
  text-align: center;
  margin-top: 2rem;
  position: relative;
  overflow: hidden;
  z-index: 1; /* Ensure footer is above other content */
}

/* Header Container */
.footer-header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

/* Logo */
.footer-logo {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 3px solid #fff;
  background-image: linear-gradient(to bottom right, #00bcd4, #ff4081);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  margin-right: 0.5rem;
  position: relative;
  animation: glow 1.5s infinite alternate;
}

/* Name */
.footer-name {
  font-size: 1.8rem;
  font-weight: bold;
  color: #00bcd4;
  text-align: left;
  letter-spacing: 1px;
}

/* Box Container */
.footer-box-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 1rem;
}

/* Box */
.footer-box {
  flex: 1 1 30%;
  margin: 0.5rem;
  padding: 2rem;
  background-color: #fff;
  color: #333;
  border-radius: 12px;
  text-align: center;
  position: relative;
  z-index: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Heading */
.footer-heading {
  font-size: 1.4rem;
  color: #0e2431;
  margin-bottom: 1rem;
  font-weight: bold;
  letter-spacing: 0.5px;
}

/* Paragraph */
.footer-paragraph {
  font-size: 1.4rem;
  color: #555;
  line-height: 1.8;
  margin: 0 auto;
  max-width: 90%;
}

/* Link */
.footer-link {
  display: block;
  color: #0e2431;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  transition: color 0.3s, transform 0.3s;
  letter-spacing: 0.5px;
}

/* Icon */
.footer-icon {
  margin-right: 0.5rem;
  font-size: 1.4rem;
}

/* Share Section */
.footer-share {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

/* Social Link */
.footer-social-link {
  font-size: 1.5rem;
  color: #0e2431;
  transition: color 0.3s, transform 0.3s;
  text-decoration: none;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #f0f0f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* Social Link Hover */
.footer-social-link:hover {
  color: #00bcd4;
  transform: scale(1.1);
  background-color: #e0f7fa;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Credit */
.footer-credit {
  font-size: 1.4rem;
  color: #ccc;
  margin-top: 1rem;
}

/* Glow Animation */
@keyframes glow {
  from {
    box-shadow: 0 0 5px #00bcd4, 0 0 10px #00bcd4, 0 0 15px #00bcd4;
  }
  to {
    box-shadow: 0 0 10px #ff4081, 0 0 15px #ff4081, 0 0 20px #ff4081;
  }
}

